<template>
  <div class="box">
    <div>
      <h2>{{$t('app.cooperation.record')}}</h2>
      <div class="aikatietosuodatin">
        <el-date-picker v-model="time" 
        type="date" 
        :placeholder="$t('app.cooperation.rq')" 
          value-format="timestamp"
          align="center"
          @change="optionDate">
        </el-date-picker>
      </div>
      <div class="head"><span class="span1">{{$t('app.cooperation.Amount')}}</span><span
          class="span2">{{$t('app.cooperation.date')}}</span><span class="span3">{{$t('app.cooperation.sy')}}</span>
      </div>
      <div class="list" v-for="(item,index) in list" :key="index">
        <p class="p1">{{item.investmentAmounts}} USDT</p>
        <p class="p2">{{item.time}}</p>
        <p class="p3">{{item.earnings | numFilter4}} USDT</p>
      </div>
    </div>
    <router-link :to="{path: '/Cooperation'}"><img src="../../assets/img/fanhui.png" alt=""></router-link>
  </div>
</template>
  
<script>
import { ethers } from 'ethers'
import ReleasedByDay from "../../contracts/ReleasedByDay.json"
import { initSigner } from '../../utlis/index'
export default {
  data() {
    return {
      address: '',
      contracts: {},
      list: [],
      time: ''
    }
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    clearInterval(this.initTimer)
  },
  computed: {
    coinNumber() {
      let num = '-'
      if (this.UNumber) {
        num = Number(this.UNumber) / 150
      }
      return num
    },
    // 邀请地址
    url() {
      if (!this.address) return '-'
      let url = location.origin + '?referee=' + this.address
      return url
    },
  },
  methods: {
    async init() {
      let { address, provider, signer } = await initSigner()
      this.address = address

      this.contracts.ReleasedByDay = new ethers.Contract(this.$common.ReleasedByDay, ReleasedByDay, provider).connect(signer)
      this.getmessage()
      this.getData()
      clearInterval(this.initTimer)
      this.initTimer = setInterval(() => {
        this.getData()
      }, 20000);
    },
    async getData() {

    },
    getmessage() {
      if (!this.address) return false
      this.contracts.ReleasedByDay.detail(this.address).then(res => {
        this.list = []
        res[0].forEach((item, index) => {
          console.log();
          if (ethers.utils.formatUnits(item, 18) == 0) return
          this.$set(this.list, index, {
            investmentAmounts: ethers.utils.formatUnits(item, 18),
            time: this.$dayjs(ethers.utils.formatUnits(res[1][index], 0) * 1000).format('YYYY-MM-DD HH:mm:ss'),
            earnings: ethers.utils.formatUnits(res[2][index], 18),
          })
        })
      })
    },
    //获取日期
    optionDate() {
      this.list = []
      this.contracts.ReleasedByDay.detail(this.address).then(res => {
        // console.log(res);

        for (let index = 0; index < res[0].length; index++) {
          if (ethers.utils.formatUnits(res[0][index], 18) == 0) continue
          console.log(Number(res[1][index]) > (this.time / 1000),Number(res[1][index]) <= (this.time / 1000 + 86400));
          // console.log(Number(res[1][index]),(this.time / 1000 + 300));
          if ((Number(res[1][index]) >= (this.time / 1000)) && (Number(res[1][index]) <= (this.time / 1000 + 86400))) {
            // console.log(1);
            // console.log(index);
            // console.log(Number(res[1][index]) > (this.time / 1000),Number(res[1][index]) <= (this.time / 1000 + 300));
            // console.log(Number(res[1][index]) <=  (this.time/1000 + 300));
            this.$set(this.list, index, {
              investmentAmounts: ethers.utils.formatUnits(res[0][index], 18),
              time: this.$dayjs(ethers.utils.formatUnits(res[1][index], 0) * 1000).format('YYYY-MM-DD HH:mm:ss'),
              earnings: ethers.utils.formatUnits(res[2][index], 18),
            })
          } else {
            // console.log(2);
            continue
          }
        }
        console.log(this.list);
      })
    }
  }
}
</script>
  
<style lang="scss" scoped>
.box {
  padding: 1.4rem 0.44rem 0.4rem;
  max-width: 400px;
  margin: 0 auto;

  >a {
    cursor: pointer;

    img {
      width: 20px;
      position: fixed;
      left: 10px;
      top: 75px;
    }
  }

  >div {
    margin: 0 auto;

    h2 {
      text-align: center;
      margin-bottom: 20px;
    }

    .aikatietosuodatin {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }

    .head {
      display: flex;
      border-bottom: 1px solid #fff;
      margin-bottom: 20px;

      .span1 {
        display: block;
        width: 90px;
        font-size: 16px;
      }

      .span2 {
        display: block;
        width: 160px;
        font-size: 16px;
      }

      .span3 {
        display: block;
        width: 80px;
        font-size: 16px;
      }
    }

    .list {
      display: flex;
      font-size: 12px;
      margin-bottom: 10px;
      border-bottom: 1px solid rgba(204, 204, 204, 0.295);

      .p1 {
        width: 90px;
      }

      .p2 {
        width: 160px;
      }

      .p3 {
        width: 80px;
      }
    }
  }
}
</style>